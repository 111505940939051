import React, { useCallback, useEffect, useState } from 'react';
import AutorenewIcon from 'mdi-react/AutorenewIcon';
import { Card, CardBody, Col, Row } from 'reactstrap';
import axios from 'axios';
import { PieChart, Pie } from 'recharts';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { jwtDecode } from 'jwt-decode';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CountRequestType from './CountRequestType';
import { getToken } from '../../../services/auth';
import {
  brMoneyMask,
  requestServicesDescripton
} from '../../../shared/utils/utils';

const pieChartData = [
  { value: 0, fill: '#142357' },
  { value: 100, fill: '#f2f4f7' }
];

const request = {
  captcha: 0,
  cpf: 0,
  hired: 0,
  price: 0,
  profile: 0,
  reader: 0,
  used: 0
};

const CountRequest = () => {
  const [data, setData] = useState(request);
  const [requestServices, setRequestServices] = useState([]);
  const [dataGraph, setDataGraph] = useState(pieChartData);
  const [balance, setBalance] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [type, setType] = useState('');
  const history = useHistory();

  const client = localStorage.getItem('client')
    ? JSON.parse(localStorage.getItem('client'))
    : '';

  const checkToken = () => {
    const decodedToken = jwtDecode(getToken());
    if (decodedToken.sub.chargeType) {
      setType(decodedToken.sub.chargeType);
    }
  };

  const fetchData = async () => {
    try {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      setRefresh(true);
      const apiUrl = `${process.env.REACT_APP_WAS_KEY}/requestCount`;
      const res = await axios.get(apiUrl, {
        headers: { Authorization: `Bearer ${getToken()}` },
        cancelToken: source.token
      });
      setDataGraph([
        { value: res.data.used, fill: '#142357' },
        { value: res.data.hired - res.data.used, fill: '#f2f4f7' }
      ]);
      const { reader, validation } = res.data;
      // const dataArray = Object.entries(filteredData);
      const dataArray = [['reader', reader], ['validation', validation]]
      dataArray.sort((a, b) => b[1] - a[1]);
      setRequestServices(dataArray);
      setData(res.data);
      return () => {
        source.cancel();
      };
    } catch (e) {
      throw new Error(e);
    } finally {
      setRefresh(false);
    }
  };

  const getBalance = useCallback(() => {
    const url = `${process.env.REACT_APP_WAS_KEY}/wallet/balance`;

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'x-api-key': client.owner
        }
      })
      .then((res) => {
        setBalance(res.data.balance);
      });
  }, [client]);

  useEffect(() => {
    getBalance();
  }, [getBalance]);

  useEffect(() => {
    fetchData().catch((e) => {
      throw e;
    });
    checkToken();
  }, []);

  const goToRecharge = () => history.push('/paymentInformation');

  return (
    <>
      {type === 'CREDIT' ? (
        <Col xs={12} md={12} xl={4}>
          <Card>
            <CardBody className="panel__body pb-0">
              {refresh ? (
                <div className="panel__refresh">
                  <LoadingIcon />
                </div>
              ) : (
                ''
              )}
              <div className="panel__btns">
                <button
                  className="panel__btn"
                  aria-label="panel__btn"
                  type="button"
                  onClick={getBalance}
                >
                  <AutorenewIcon />
                </button>
              </div>
              <div className="panel__title" style={{ marginBottom: '10px' }}>
                <h5 className="bold-text">Saldo da Conta</h5>
              </div>
              <Row>
                <Col xl={12} className="text-center">
                  <div
                    className="d-flex text-center align-items-center justify-content-center"
                    style={{ height: '9rem' }}
                  >
                    <Row>
                      <Col xl={12}>
                        <span className="balance-value">
                          R$ {brMoneyMask(balance)}
                        </span>
                      </Col>
                      <Col xl={12}>
                        <button
                          type="button"
                          className="btn btn-primary mt-4"
                          style={{ padding: '6px 50px' }}
                          onClick={() => goToRecharge()}
                        >
                          Recarregar
                        </button>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      ) : (
        <Col xs={12} md={12} xl={4}>
          <Card>
            <CardBody className="panel__body">
              {refresh ? (
                <div className="panel__refresh">
                  <LoadingIcon />
                </div>
              ) : (
                ''
              )}
              <div className="panel__btns">
                <button
                  className="panel__btn"
                  aria-label="panel__btn"
                  type="button"
                  onClick={fetchData}
                >
                  <AutorenewIcon />
                </button>
              </div>
              <div className="panel__title" style={{ marginBottom: '10px' }}>
                <h5 className="bold-text">Consumo de requisições</h5>
              </div>
              <div className="dashboard__current-users">
                <div className="dashboard__current-users-chart">
                  <PieChart height={150} width={280}>
                    <Pie
                      data={dataGraph}
                      dataKey="value"
                      cx={135}
                      cy={140}
                      startAngle={180}
                      endAngle={0}
                      innerRadius={100}
                      outerRadius={128}
                      paddingAngle={0}
                    />
                  </PieChart>
                  <p className="dashboard__current-users-label">{data.used}</p>
                </div>
                <div className="dashboard__current-users-info" dir="ltr">
                  <p className="dashboard__current-users-day">
                    <span>Requisições Min</span>
                    <span>0</span>
                  </p>
                  <p className="dashboard__current-users-day">
                    <span>Requisições Max</span>
                    <span>{data.hired}</span>
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      )}
      <Col xs={12} md={12} xl={8}>
        <Row className={ refresh ? "" : 'd-flex align-items-center h-100'}>
          {refresh ? (
            <Card className="p-0">
              <CardBody>
                <div style={{ height: '10rem' }}>
                  <div className="panel__refresh">
                    <LoadingIcon />
                  </div>
                </div>
              </CardBody>
            </Card>
          ) : (
            <>
              {requestServices.map(([key, value]) => (
                <Col xs={12} md={12} xl={6}>
                  <CountRequestType
                    title={requestServicesDescripton[key]}
                    value={value}
                    percent={+((value * 100) / data.used).toFixed() || 0}
                    loading={refresh}
                  />
                </Col>
              ))}
            </>
          )}
        </Row>
      </Col>
    </>
  );
};

export default CountRequest;
