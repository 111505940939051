import React, { useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import FileDocumentOutline from 'mdi-react/FileDocumentOutlineIcon';
import { useForm, Controller } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import LoadingIcon from 'mdi-react/LoadingIcon';
import axios from 'axios';
import classNames from 'classnames';
import {
  accessKeyMask,
  inputAccessKeyMask,
  JSONA,
  translatedStatus
} from '../../../shared/utils/utils';
import { getToken } from '../../../services/auth';
import NoteInformation from './NoteInformation';
import NotFoundNote from './NotFoundNote';

const SearchNote = () => {
  const [load, setLoad] = useState(false);
  const [accessKeyFormated, setAccessKeyFormated] = useState();
  const [note, setNote] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();

  const formatData = (res) => {
    const aux = {
      accessKey: accessKeyMask(res.accessKey),
      createdAt: res.createdAt,
      updatedAt: res.updatedAt,
      status: translatedStatus(res.status),
      state: JSONA[res.accessKey.substring(0, 2)].name,
      extraOptions: res.extraOptions
    };
    return aux;
  };

  const onSubmit = (data) => {
    setLoad(true);
    setNotFound(false);
    setNote(null);
    const accessKey = data.accessKey.replaceAll(' ', '').replaceAll('_', '');
    setAccessKeyFormated(accessKey);
    const apiUrlOnSubmit = `${process.env.REACT_APP_WAS_KEY}/serchNota/${accessKey}`;
    axios
      .get(apiUrlOnSubmit, {
        headers: { Authorization: `Bearer ${getToken()}` }
      })
      .then((res) => {
        setNote(formatData(res.data));
      })
      .catch((err) => {
        if (
          err.response.data.message ===
          'Sua chave de acesso não foi encontrada.'
        )
          setNotFound(true);
      })
      .finally(() => setLoad(false));
  };

  const isEven = (accessKey) =>
    accessKey.replaceAll(' ', '').replaceAll('_', '').length === 44;

  const expandClass = classNames(
    {
      icon: true,
      expand: true,
      'expand--load': load
    },
    'btn btn-primary account__btn account__btn--small'
  );

  return (
    <>
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody>
            <div className="project-summary">
              <div className="card__title project-summary__card-title">
                <h5 className="bold-text">Consulta Nota Fiscal</h5>
              </div>
              <Row>
                <Col md={12} lg={12} xl={12}>
                  <form className="form" onSubmit={handleSubmit(onSubmit)}>
                    <Row
                      className="account-row"
                      style={{ display: 'flex', alignItems: 'flex-end' }}
                    >
                      <Col md={12} lg={4} xl={4} className="account-col mt-4">
                        <div
                          className="form__form-group"
                          style={{ marginBottom: '0px' }}
                        >
                          <span className="form__form-group-label">
                            Chave de Acesso
                          </span>
                          <div className="form__form-group-field">
                            <div className="form__form-group-icon">
                              <FileDocumentOutline />
                            </div>
                            <Controller
                              name="accessKey"
                              rules={{
                                required: 'Este campo é obrigatório.',
                                validate: isEven
                              }}
                              control={control}
                              render={({ field }) => (
                                <MaskedInput
                                  {...field}
                                  mask={inputAccessKeyMask}
                                  name="accessKey"
                                  type="text"
                                  placeholder=""
                                />
                              )}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={12} lg={2} xl={2} className='mt-4'>
                        <button
                          className={expandClass}
                          type="submit"
                          disabled={load}
                          style={{
                            padding: '5px 20px',
                            borderRadius: '0px',
                            margin: '0px'
                          }}
                        >
                          <LoadingIcon /> Consultar
                        </button>
                      </Col>
                    </Row>
                    {errors.accessKey && (
                      <span className="error">{errors.accessKey.message}</span>
                    )}
                    {errors.accessKey &&
                      errors.accessKey.type === 'validate' && (
                        <div className="error">
                          A chave de acesso deve conter 44 dígitos.
                        </div>
                      )}
                  </form>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
      {note && (
        <NoteInformation note={note} accessKeyFormated={accessKeyFormated} />
      )}
      {notFound && <NotFoundNote accessKeyFormated={accessKeyFormated} />}
    </>
  );
};

export default SearchNote;
