import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Col, Container, Row } from 'reactstrap';
import SearchNote from './components/SearchNote';

const Operation = () => {

  const { bonusAccountPercentage } = localStorage.getItem('client')
    ? JSON.parse(localStorage.getItem('client'))
    : '';

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Container className="dashboard">
      {bonusAccountPercentage > 0 && (<Row style={{ marginBottom: '30px' }} className='d-flex justify-content-center'>
        <Col xs={12} md={12} xl={7} className="text-center">
          <img src="https://was-files-generic.s3.us-east-1.amazonaws.com/images/banner-dashboard.png"
              alt="banner" style={{ borderRadius: '20px' }} className='d-none d-md-block'/>
            <img src="https://was-files-generic.s3.us-east-1.amazonaws.com/images/banner-dashboard-mobile.png"
              alt="banner" style={{ borderRadius: '20px' }} className='d-block d-md-none'/>
        </Col>
      </Row>
      )}
      <Row style={{ marginBottom: '30px' }}>
        <Col xs={12} md={12} xl={10}>
          <h3 className="page-title">Operacional</h3>
        </Col>
      </Row>
      <Row>
        <SearchNote />
      </Row>
    </Container>
  );
};
export default Operation;
